<template>
  <div>
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 v-if="VehicleID">
          Edit Vehicle
        </h2>
        <h2 v-else>
          Add Vehicle
        </h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="8">
        <div class="white-bg-container">
          <b-row>
            <b-col>
              <span>Vehicle Make/Model</span>
            </b-col>
            <b-col>
              <b-form-input
                id="vehicleName"
                v-model="Name"
                name="vehicleName"
              />
            </b-col>
          </b-row>
          <b-row class="pt-1 pb-1">
            <b-col>
              <span>Unit Number</span>
            </b-col>
            <b-col>
              <b-form-input
                id="vehicleUnitNumber"
                v-model="UnitNumber"
                name="vehicleUnitNumber"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Department/Classification</span>
            </b-col>
            <b-col>
              <b-form-input
                id="vehicleDepartment"
                v-model="Department"
                name="vehicleDepartment"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveForm()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Name: "",
      UnitNumber: "",
      Department: "",

      VehicleID: "",
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
        },
        {
          text: 'Utility',
          href: '/admin/utility'
        },
        {
          text: "Manage Vehicles",
          href: "/admin/utility/manage-vehicles"
        },
        {
          text: this.VehicleID ? "Edit Vehicle" : "Add Vehicle",
        },
      ]
    },
  },
  created() {
    this.VehicleID = this.$route.params.vehicleID;
    if (this.VehicleID) {
      apiService
          .get("vehicle/" + this.VehicleID + "/detail")
          .then(res => {
            this.Name = res.data.Name;
            this.UnitNumber = res.data.UnitNumber;
            this.Department = res.data.Department;
          })
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    resetForm() {
      this.$router.push('/admin/utility/manage-vehicles')
    },
    saveForm() {
      const postData = {
        "Name": this.Name,
        "UnitNumber": this.UnitNumber,
        "Department": this.Department,
      }
      const endpoint = this.VehicleID ? "vehicle/" + this.VehicleID + "/edit" : "vehicle/create"
      apiService
          .post(endpoint, postData)
          .then((res) => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Vehicle has been created or updated");
              this.$router.push('/admin/utility/manage-vehicles')
            }
          })
    },
  }
}
</script>

<style scoped>

</style>